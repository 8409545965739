import { createSelector } from "reselect";
import { Question } from "../../model/question/Question";
import { Section } from "../../model/section/section_data";
import { RootState } from "../ReduxStore";

export const selectExamRuntime = (state: RootState) => state.examRuntime;
export const selectExamStage = (state: RootState) => selectExamRuntime(state).stage;

export const selectExam = (state: RootState) => selectExamRuntime(state).exam;
export const selectExamName = (state: RootState) => selectExam(state).full_name;
export const selectExamID = (state: RootState) => selectExamRuntime(state).exam.id;
export const selectExamIDOrigin = (state: RootState) => selectExamRuntime(state).examID;
export const selectExamResult = (state: RootState) => selectExam(state).result;
export const selectExamAction = (state: RootState) => selectExam(state).action;
export const selectSections = (state: RootState) => selectExam(state).sections;

export const selectNextSectionID = (state: RootState) => {
    const exam = selectExam(state);
    for (let i = 0; i < exam.sections.length; i++) {
        if (!exam.sections[i].action.finished) {
            return i;
        }
    }
    return 0;
};

export const selectNextSection = (state: RootState) => {
    const exam = selectExam(state);
    const nextSectionID = selectNextSectionID(state);
    return exam.sections[nextSectionID];
};

export const selectSectionIndex = (state: RootState) => selectExamRuntime(state).sectionIndex;
const emptySection = new Section();
export const selectSection = createSelector(
    [selectExam, selectSectionIndex],
    (exam, sectionIndex) => {
        return sectionIndex !== -1 ? exam.sections[sectionIndex] : emptySection;
    }
);
export const selectSectionName = (state: RootState) => selectSection(state).name;

export const selectSectionAction = (state: RootState) => selectSection(state).action;

export const selectPageIndex = (state: RootState) => selectExamRuntime(state).pageIndex;
export const selectQuestionIndex = (state: RootState) => selectExamRuntime(state).questionIndex;
export const selectReviewIndex = (state: RootState) => selectExamRuntime(state).reviewIndex;

const emptyQuestion = new Question();
export const selectExamQuestion = (state: RootState) => {
    const qs = selectSection(state).questions;
    if (state.examRuntime.questionIndex >= qs.length) {
        return emptyQuestion;
    }
    return qs[state.examRuntime.questionIndex];
};

export const selectFromURL = (state: RootState) => selectExamRuntime(state).fromURL;

export const selectSearchWrod = (state: RootState) => selectExamRuntime(state).searchWord;

export const selectPaused = (state: RootState) => selectExamRuntime(state).paused;
