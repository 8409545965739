export class EnumItem {
    value: number = 0;
    name: string = "";
    disabled: boolean = false;
    constructor(val: number, name: string, disabled?: boolean) {
        this.value = val;
        this.name = name;
        this.disabled = disabled ? true : false;
    }
}

export function Make_GetEnumName(items: EnumItem[]) {
    return (val: number): string => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === val) {
                return items[i].name;
            }
        }
        return "";
    };
}

export interface EnumDisplay {
    value: number;
    name: string;
    disabled?: boolean;
}
