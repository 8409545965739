import { RateCount } from "../Count";
import { Answer } from "../question/Answer";
import { Source } from "./source";

export class Wrong {
    answer: Answer = new Answer();
    wrong_time_str: string = "";
    source: Source = new Source();
    count: RateCount = new RateCount();
}
