import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo, useState } from "react";
import { EnumDisplay } from "../../../../model/EnumItem";

interface BaseProps {
    items: EnumDisplay[];
    title?: string;
    onChange?: (item: EnumDisplay) => void;
}

export interface useSelectEnumItemProps extends BaseProps {
    defaultIndex?: number; // defaultIndex和defaultValue二选一
    defaultValue?: number;
}

// 输入 enum列表
// 输出: 下拉框div, 选择的enum
export function useSelectEnumItem(props: useSelectEnumItemProps): [any, EnumDisplay | undefined] {
    const {
        items,
        title,
        defaultValue: defaultValueRef,
        defaultIndex: defaultIndexRef,
        onChange,
    } = props;

    const defaultIndex = useMemo(() => {
        if (defaultIndexRef !== undefined && defaultIndexRef < items.length) {
            return defaultIndexRef;
        }
        if (defaultValueRef !== undefined) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].value === defaultValueRef) {
                    return i;
                }
            }
        }
        return undefined;
    }, [defaultValueRef, items, defaultIndexRef]);

    const [index, setIndex] = useState<number | undefined>(defaultIndex);
    return [
        <SelectEnumItemDisplay
            index={index}
            setIndex={setIndex}
            items={items}
            title={title}
            onChange={onChange}
        />,
        index !== undefined ? items[index] : undefined,
    ];
}

export interface SelectEnumItemDisplayProps extends BaseProps {
    index: number | undefined;
    setIndex: (index: number) => void;
}

export function SelectEnumItemDisplay(props: SelectEnumItemDisplayProps) {
    const { items, title, index, setIndex, onChange: onChangeRef } = props;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {title && <Box sx={{ mr: "10px" }}>{title}：</Box>}
            <FormControl size="small" sx={{ minWidth: "200px" }}>
                <Select
                    value={index !== undefined ? index.toString() : ""}
                    onChange={(event: SelectChangeEvent) => {
                        if (event.target.value.length === 0) {
                            return;
                        }
                        let i = parseInt(event.target.value);
                        setIndex(i);
                        if (onChangeRef !== undefined && i >= 0 && i < items.length) {
                            onChangeRef(items[i]);
                        }
                    }}
                >
                    {items.map((item, index) => (
                        <MenuItem key={index} value={index.toString()}>
                            {item.disabled ? <del>{item.name}</del> : item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
