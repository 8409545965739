export class CraftSearchOption {
    filter: CraftFilter = new CraftFilter();
    order: CraftOrder = new CraftOrder();
}

export class CraftFilter {
    collected: boolean = false;
    noted: boolean = false;
    wrong: boolean = false;
}

export class CraftOrder {
    kind: number = 0;
    is_desc: boolean = false;
}

export enum CraftOrderKind {
    CollectTime = 1, // 收藏时间倒序
    NoteTime = 2, // 笔记时间倒序
    WrongTime = 3, // 错题时间倒序
    WrongCount = 4, // 做错次数从大到小
    UsedCount = 5, // 练习次数从大到小
}
