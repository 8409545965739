import { Box, BoxProps, Stack, StackProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface TitleDivProps {
    title?: string;
    blod?: boolean;
    sx?: StackProps["sx"];
    mt?: boolean;
    updown?: boolean; // 分成两行
    minWidth?: BoxProps["minWidth"];
}

// 左标题, 右any(children)
export function TitleDiv(props: PropsWithChildren<TitleDivProps>) {
    const { title, blod, sx, mt, updown, minWidth, children } = props;
    return (
        <Stack
            direction={updown ? "column" : "row"}
            sx={{
                alignItems: updown ? undefined : "center",
                mt: mt ? "30px" : "5px",
                mb: "5px",
                ...sx,
            }}
        >
            {title && <span style={{ fontWeight: blod ? 700 : undefined }}>{title}：</span>}
            <Box
                sx={{
                    minWidth: minWidth || "250px",
                    ml: updown ? undefined : "20px",
                    mt: updown ? "20px" : undefined,
                }}
            >
                {children}
            </Box>
        </Stack>
    );
}
