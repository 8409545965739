import { RateCount } from "../Count";
import { EnumItem } from "../EnumItem";
import { Question } from "../question/Question";

export class Section {
    data: SectionData = new SectionData();
    action: SectionAction = new SectionAction();
    result: SectionResult = new SectionResult();
    questions: Question[] = [];
    name: string = "";
}

export class SectionData {
    id: string = "";
    kind: number = 0;
    kind_str: string = "";
    time_limit_seconds: number = 0;
    question_ids: string[] = [];
    locations_desp: string = "";
}
export class SectionAction {
    used_time_seconds: number = 0;
    finished: boolean = false;
    cursor: number = 0;
}

export class SectionResult {
    judged: boolean = false;
    correct: RateCount = new RateCount();
    not_answered: number = 0;
}

export enum SectionKind {
    None = 0,
    Reading = 1,
    Logic = 2,
    Game = 3,
}

export const SectionKindItems = [
    new EnumItem(SectionKind.Reading, "RC"),
    new EnumItem(SectionKind.Logic, "LR"),
    new EnumItem(SectionKind.Game, "LG", true),
];
