import { Craft } from "../craft/user_craft";

export class QuestionInfo {
    id: string = "";
    title: string = "";
    kind_str: string = "";
    brief: string = "";
    locked: boolean = false;
    craft: Craft = new Craft();
}
