import { Craft } from "../craft/user_craft";
import { Answer } from "./Answer";
import { QuestionData } from "./QuestionData";

// Code generated by transtool(v0.0.10).
export class Question {
    order: number = 0;
    data_id: string = "";
    data: QuestionData = new QuestionData();
    paper: QuestionPaper = new QuestionPaper();
    craft: Craft = new Craft();
}

// Code generated by transtool(v0.0.10).
export class QuestionPaper {
    id: string = "";
    action: QuestionAction = new QuestionAction();
    result: QuestionResult = new QuestionResult();
}

// Code generated by transtool(v0.0.10).
export class QuestionAction {
    answer: Answer = new Answer();
    hidden_options: boolean[] = [];
    flagged: boolean = false;
    used_time_seconds: number = 0;
}

// Code generated by transtool(v0.0.10).
export class QuestionResult {
    correct: boolean = false;
}
