import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

// 文本框编辑, 失去焦点时触发自动保存
export function AutoSaveTextField(props: {
    text: string;
    onSave: (t: string) => Promise<boolean>;
}) {
    const { text, onSave } = props;

    const [draft, setDraft] = useState("");

    useEffect(() => {
        setDraft(text);
    }, [text]);

    return (
        <TextField
            value={draft}
            onChange={(e) => setDraft(e.target.value)}
            onBlur={async (e) => {
                if (e.target.value !== text) {
                    const resolve = await onSave(e.target.value);
                    if (!resolve) {
                        setDraft(text);
                    }
                }
            }}
        />
    );
}
