import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router";
import { AnyAction } from "redux";
import { SectionData } from "../../model/section/section_data";

export function isDialogAction(action: AnyAction): boolean {
    return action.type.startsWith("dialog");
}

function getInitState() {
    return {
        editSection: {
            open: false,
            data: new SectionData(),
            navigate: {} as NavigateFunction,
        },
        permisson: false,
    };
}

export const dialogRuntimeSlice = createSlice({
    name: "dialog",
    initialState: getInitState(),
    reducers: {
        actionSetEditSectionDialog: (
            state,
            action: PayloadAction<{
                open: boolean;
                data: SectionData;
                navigate: NavigateFunction;
            }>
        ) => {
            state.editSection = action.payload;
        },
        actionSetPermissionDialog: (state, action: PayloadAction<boolean>) => {
            state.permisson = action.payload;
        },
    },
});

export const { actionSetEditSectionDialog, actionSetPermissionDialog } = dialogRuntimeSlice.actions;

export default dialogRuntimeSlice.reducer;
