import { PaletteColorOptions } from "@mui/material";
import { lightenColor } from "./color_util";

export const Colors = {
    primary: "#3D787E",
    primarya: "rgba(61,120,126,0.2)",
    secondary: "rgb(6,73,78)",
    third: "rgb(230,238,239)",

    white: "#FFFFFF",
    black: "#000000",
    navgray: "rgb(116,122,134)", // 导航栏

    correct: "rgb(60,124,130)",
    wrong: "#D34329",

    examBackground: "#232437", // 模考背景色
    examGreen: "rgb(60,124,130)", // 模考绿色
    examRed: "rgb(124,49,64)", // 模考标题红色
    examDarkBlue: "rgb(31,44,78)",
    examLightBlue: "rgb(198,210,222)",
    examGray: "rgb(174,174,174)",
    examButtonGray: "rgb(102,102,102)",
    examNav: "rgb(41,90,132)",
    examButtonLightGray: "rgb(221,221,221)",
    examButtonBrown: "rgb(165,104,41)",
    examButtonLightGray2: "rgb(237,239,241)",
    examTextHide: "#ababab",
    examTextBorder: "rgb(62,141,142)",
    examDarkGray: "rgb(97,105,111)",

    steelBlue: "#5C76B7",
    gray: "#848484",
    gray2: "rgb(200,200,200)",
    gray3: "rgb(230,230,230)",
    ddgray: "rgb(112,112,112)", // 搜索灰色
    ddgraya3: "rgba(112,112,112,0.1)",

    jiangHuang: "rgb(255,199,115)",
    ddbackground: "rgb(108,117,210)",
    ddbackgrounda2: "rgb(108,117,210,.3)",
    ddbackground3: "rgb(240,240,240)",
    dddisable: "rgba(112,112,112,0.5)",

    ddlabelgray: "rgb(112,112,112)",
    ddlabelred: "rgba(191,72,62,.8)",
    ddlabelblue: lightenColor("#0000FF", 30),
    ddlabelyellow: lightenColor("rgb(255,199,115)", 15),
    ddlabelgreen: "rgba(41,223,8,0.15)",
    continue: "rgb(122,157,84)",
    review: "rgb(165,74,55)",
    restart: "#D79416",
    admin: "#0000FF",
    officialred: "rgb(165,74,55)",
    officialreda: "rgba(165,74,55,0.2)",
};
export type ColorsType = typeof Colors;

export const ColorAdmin = Colors["admin"];
export const ColorDDGray = Colors["ddgray"];
export const ColorDDGrayA3 = Colors["ddgraya3"];
export const ColorJiangHuang = Colors["jiangHuang"];
export const ColorOfficialRed = Colors["officialred"];
export const ColorOfficialRedA = Colors["officialreda"];
export const ColorOldPrimary = Colors["ddbackground"];
export const ColorOldPrimaryA2 = Colors["ddbackgrounda2"];
export const ColorBackGround3 = Colors["ddbackground3"];

type CustomPaletteType = {
    [key in keyof ColorsType]: PaletteColorOptions;
};
export interface CustomPalette extends CustomPaletteType {}

type ColorOverridesType = {
    [key in keyof ColorsType]: true;
};
export interface ColorOverrides extends ColorOverridesType {}
