import { EditSectionDialog } from "../section/edit_dialog";
import PermissionMsgDialog from "./PermissionDialog";
import { VIPDialog } from "./VIPDialog";

export function Dialogs() {
    return (
        <>
            <PermissionMsgDialog />
            <VIPDialog />
            <EditSectionDialog />
        </>
    );
}
