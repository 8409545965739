import { BookTemplate } from "./Template";

export class Book {
    id: string = "";
    user_id: string = "";
    template: BookTemplate = new BookTemplate();
    exam_id: string = "";
    status: number = 0;

    limit: number = 0;
    title: string = "";
    sub_title: string = "";

    isBackend: boolean = false;
}
