import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Exam, ExamResult } from "../../model/exam/Exam";
import { Question } from "../../model/question/Question";
import { SectionAction } from "../../model/section/section_data";

export function isExamAction(action: AnyAction): boolean {
    return action.type.startsWith("exam_runtime");
}

export enum ExamStage {
    Entrance = 0, // 入口: Section列表
    Intro = 1, // 介绍页
    InExam = 2,
    // EndIntro = 2,
    // Rest = 3,
    // Review = 4,
    Result = 5, // 查看考试结果
}

export const examRuntimeSlice = createSlice({
    name: "exam_runtime",
    initialState: {
        examID: "",
        exam: new Exam(),
        stage: ExamStage.Entrance,

        sectionIndex: 0,
        questionIndex: 0,
        pageIndex: 1,

        reviewIndex: 0,
        fromURL: "",

        searchWord: "",

        paused: false,
    },
    reducers: {
        actionCleanExamRuntime: (state) => {
            state.examID = "";
            state.exam = new Exam();
            state.stage = ExamStage.Entrance;
            state.sectionIndex = 0;
            state.questionIndex = 0;
            state.pageIndex = 1;
            state.reviewIndex = 0;
            state.fromURL = "";
        },
        actionSetExamIDOrigin: (state, action: PayloadAction<string>) => {
            state.examID = action.payload;
        },
        actionSetExam: (state, action: PayloadAction<Exam>) => {
            state.exam = action.payload;
            state.exam.isBackend = true;
            state.sectionIndex = action.payload.cursor.section_index;
            state.questionIndex = action.payload.cursor.question_index;
            state.pageIndex = action.payload.cursor.question_index + 1;

            state.examID = action.payload.id;
        },
        actionSetExamResult: (state, action: PayloadAction<ExamResult>) => {
            state.exam.result = action.payload;
        },
        actionSetExamStage: (state, action: PayloadAction<ExamStage>) => {
            state.stage = action.payload;
        },
        actionSetSectionIndex: (state, action: PayloadAction<number>) => {
            state.sectionIndex = action.payload;
        },
        actionSetPageIndex: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload;
            const questionIndex = action.payload - 1;
            const s = state.exam.sections[state.sectionIndex];
            if (0 <= questionIndex && questionIndex < s.questions.length) {
                state.questionIndex = questionIndex;
            } else {
                state.questionIndex = 0;
            }
        },
        actionSetReviewIndex: (state, action: PayloadAction<number>) => {
            state.reviewIndex = action.payload;
        },
        actionSetSectionFinish: (state) => {
            const s = state.exam.sections[state.sectionIndex];
            s.action.finished = true;
        },
        actionSetSectionUsedTime: (state, action: PayloadAction<number>) => {
            const s = state.exam.sections[state.sectionIndex];
            s.action.used_time_seconds = action.payload;
        },
        actionSetExamQuestion: (state, action: PayloadAction<Question>) => {
            const s = state.exam.sections[state.sectionIndex];
            s.questions[state.questionIndex] = action.payload;
        },
        actionSetSectionAction: (state, action: PayloadAction<SectionAction>) => {
            const sec = state.exam.sections[state.sectionIndex];
            sec.action = action.payload;
        },
        actionSetFromURL: (state, action: PayloadAction<string>) => {
            state.fromURL = action.payload;
        },
        actionSetSearchWord: (state, action: PayloadAction<string>) => {
            state.searchWord = action.payload;
        },
        actionSetPaused: (state, action: PayloadAction<boolean>) => {
            state.paused = action.payload;
        },
    },
});

export const {
    actionCleanExamRuntime,
    actionSetExam,
    actionSetExamResult,
    actionSetSectionIndex,
    actionSetPageIndex,
    actionSetSectionFinish,
    actionSetSectionUsedTime,
    actionSetExamStage,
    actionSetReviewIndex,
    actionSetExamIDOrigin,
    actionSetFromURL,
    actionSetSearchWord,
    actionSetExamQuestion,
    actionSetPaused,
    actionSetSectionAction,
} = examRuntimeSlice.actions;

export default examRuntimeSlice.reducer;
