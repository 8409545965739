export enum EditSectionKind {
    QuestionIDs = 1,
    SectionKind = 2,
    BatchNewGroupID = 3,
}

export class SectionEditExtraData {
    remove_overflowed_question_ids: boolean = false;
    batch_new_group_start: number = 0;
    batch_new_group_end: number = 0;
}
