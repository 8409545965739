import { Collect } from "./collect";
import { Note } from "./note";
import { Wrong } from "./wrong";

export class Craft {
    id: string = "";
    user_id: string = "";
    collect: Collect = new Collect();
    wrong: Wrong = new Wrong();
    note: Note = new Note();
}

export enum UpdateCraftKind {
    Collect = 1,
    Note = 2,
    RemoveWrong = 3, // 从错题集中去除
}
