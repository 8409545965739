import { useCallback, useState } from "react";
import { BBDialog } from "./bb_dialog";

interface ConfirmDialogProps {
    title?: string;
}

export function useConfirmDialog(
    props?: ConfirmDialogProps
): [any, (msg: string) => Promise<void>] {
    const { title, ...pp } = props || {};

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [resolver, setResolver] = useState<{
        resolve: () => void;
        reject: () => void;
    } | null>(null);

    const openConfirmBox = useCallback((msg: string): Promise<void> => {
        setMessage(msg);
        setOpen(true);
        return new Promise((resolve, reject) => {
            setResolver({ resolve, reject });
        });
    }, []);

    const handleConfirm = useCallback(() => {
        if (resolver) resolver.resolve();
    }, [resolver]);

    const handleCancel = useCallback(() => {
        if (resolver) resolver.reject();
    }, [resolver]);

    return [
        <BBDialog
            open={open}
            setOpen={setOpen}
            title={title}
            content={message}
            onConfirm={handleConfirm}
            onClose={handleCancel}
            {...pp}
        />,
        openConfirmBox,
    ];
}
