import { ThemeProvider } from "@emotion/react";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import * as jose from "jose";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { methodResumeLogin } from "./api/apiuser";
import { AlertError } from "./component/basic/data_displays/snackbar/Snackbar";
import { Dialogs } from "./component/business/dialog/dialogs";
import { ddTheme } from "./component/theme/theme";
import GetGlobalConfig from "./config/GlobalConfig";
import "./index.css";
import { AuthData, TOKEN_KEY } from "./model/AuthData";
import { ddRouter } from "./router";
import store from "./store/ReduxStore";
import { selectBackdropCount } from "./store/root/selector";
import { actionSetLogin, actionSetUserData, actionUpdateUserAuth } from "./store/user/reducer";

const LoadToken = (dispatch: any): [string, AuthData] => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        return ["", { user_id: "" } as AuthData];
    }
    const data = jose.decodeJwt(token);
    const auth = data as unknown as AuthData;
    dispatch(actionUpdateUserAuth(auth));
    return [token, auth];
};

function dealLogin(dispatch: any) {
    const cfg = GetGlobalConfig();
    if (!cfg.auto_resume_login) {
        return true;
    }
    return methodResumeLogin(dispatch, {}).then((rsp) => {
        dispatch(actionSetUserData(rsp.user));
        dispatch(actionSetLogin(true));
    });
}

const App = () => {
    const dispatch = useDispatch();

    const [loginChecked, setLoginChecked] = useState(false);

    // init
    useEffect(() => {
        console.log("api version", GetGlobalConfig().api_version);

        // 在组件加载时，添加版本号作为查询参数
        const script = document.createElement("script");
        const version =
            process.env.NODE_ENV === "production"
                ? GetGlobalConfig().api_version
                : Math.random().toString(36).slice(2);
        script.src = `/script.js?v=${version}`;
        document.body.appendChild(script);

        // load token
        const [token, auth] = LoadToken(dispatch);
        // resume login from token
        if (token.length > 0 && auth.user_id.length > 0) {
            const p = dealLogin(dispatch);
            if (p === true) {
                setLoginChecked(true);
            } else {
                p.then(() => {
                    setLoginChecked(true);
                }).catch((e) => {
                    setLoginChecked(true);
                    AlertError(e.toString());
                });
            }
        } else {
            setLoginChecked(true);
        }

        return () => {
            // 在组件卸载时，移除添加的脚本
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <AppBackdrop />
            <Dialogs />
            {loginChecked && <RouterProvider router={ddRouter} />}
        </>
    );
};

function AppBackdrop() {
    const backdropCount = useSelector(selectBackdropCount);
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropCount > 0}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

const ThemeApp = () => (
    <Provider store={store}>
        <ThemeProvider theme={ddTheme}>
            <SnackbarProvider maxSnack={5}>
                <CssBaseline />
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
);

export default ThemeApp;
