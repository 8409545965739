import { API, apiRequest } from "../api";
import {
    EditPTReq,
    EditPTRsp,
    EditReq,
    EditRsp,
    EditSectionReq,
    EditSectionRsp,
    UpdateTagsReq,
    UpdateTagsRsp,
} from "./question";

export const apiEditQuestion: API<EditReq, EditRsp> = {
    url: "/api/question/edit",
};

export function methodEditQuestion(dispatch: any, req: EditReq) {
    return apiRequest({ api: apiEditQuestion, req, dispatch });
}

export const apiUpdateTags: API<UpdateTagsReq, UpdateTagsRsp> = {
    url: "/api/question/update_tags",
};

export function methodUpdateTags(dispatch: any, req: UpdateTagsReq) {
    return apiRequest({ api: apiUpdateTags, req, dispatch });
}

export const apiEditSection: API<EditSectionReq, EditSectionRsp> = {
    url: "/api/section/edit",
};

export function methodEditSection(dispatch: any, req: EditSectionReq) {
    return apiRequest({ api: apiEditSection, req, dispatch });
}

export const apiEditPT: API<EditPTReq, EditPTRsp> = {
    url: "/api/pt/edit",
};

export function methodEditPT(dispatch: any, req: EditPTReq) {
    return apiRequest({ api: apiEditPT, req, dispatch });
}
