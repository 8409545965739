// Code generated by transtool(v0.0.10).

import { Source } from "./source";

export class Note {
    text: string = "";
    reasons: NoteReason[] = [new NoteReason()];
    source: Source = new Source();
}

export class NoteReason {
    name: string = "";
    type: number = 0;
    checked: boolean = false;
    hide: boolean = false;
}
